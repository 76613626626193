import React, { useContext, useState, useEffect } from 'react';
import Context from '../context';

import { ConfigProvider, theme as antdTheme } from 'antd';

// Importa las traducciones en español de UX
import esES from 'antd/es/locale/es_ES';
import './theme.less';

// theme.defaultAlgorithm
// theme.darkAlgorithm
// theme.compactAlgorithm

function ConfigAntdProvider({ children }) {
  const { theme } = useContext(Context);
  
  const changeTheme = () => {
    if (theme === 'light') {
      return antdTheme.defaultAlgorithm
    } else {
      return antdTheme.darkAlgorithm
    }
  }

  return (
    <ConfigProvider locale={esES} theme={{
      algorithm: changeTheme()
    }}>
      {children}
    </ConfigProvider>
  );
}

export default ConfigAntdProvider;
