import { createContext, useState, useEffect } from "react";

const Auth = createContext();

const AuthProvider = ({ children }) => {
  const [user, setuser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Obtener el token de autenticación de sessionStorage
    const authData = sessionStorage.getItem("OnApiSession");
    // Verificar si el token existe en sessionStorage
    if (authData) {
      login(authData);
    }    
  }, []);

  const login = (data) => {
    setuser(data);
    setToken(data.access_token);

    sessionStorage.setItem("OnApiSession", data);
    sessionStorage.setItem("OnApiToken", data.access_token);
  }

  useEffect(() => {
    document.cookie = `token=${token}; secure; HttpOnly; SameSite=Strict`;
  }, [token]);

  const logout = () => {
    sessionStorage.removeItem("OnApiSession");
    sessionStorage.removeItem("OnApiToken");
    setToken(null);
    setuser(null);
  }

  return (
    <Auth.Provider value={{
      login, logout,
      user, token
    }}>
      {children}
    </Auth.Provider>
  );
};

export { AuthProvider };
export default Auth;