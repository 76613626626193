import React, { useContext } from 'react';
import Context from '../context';

import logo_dark from '../assets/logo_onfield_light.gif';
import logo_light from '../assets/logo_onfield_dark.gif';

const Loading = () => {
  const { theme } = useContext(Context);

  const logo = theme === 'light' ? logo_light : logo_dark;
  const background = theme === 'light' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 9999,
        backgroundColor: background,
      }}
    >
      <img
        src={logo}
        alt="Loading..."
        style={{
          width: '5%',
        }}
      />
    </div>
  );
};
export default Loading;