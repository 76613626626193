import { createContext, useState } from "react";

import Auth, { AuthProvider } from "./auth";

const Context = createContext();

const ContextProvider = ({ children }) => {

  const [theme, setTheme] = useState('light'); //light - dark
  const changeTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  }

  return (
    <Context.Provider value={{
      Auth, theme, changeTheme,
      }}>
      <AuthProvider>
        {children}
      </AuthProvider>
    </Context.Provider>
  );
};

export { ContextProvider };
export default Context;
