import React, { lazy, Suspense, useContext } from 'react';
import { Routes, Route, Navigate, Redirect } from "react-router-dom";
import Auth from './context/auth';
import { App } from 'antd';

import ConfigAntdProvider from './globalComponents/theme';

import Login from "./pages/login";
import Loading from "./globalComponents/loading";

const LayoutApp = lazy(() => import("./globalComponents/layout"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const JobsManager = lazy(() => import("./pages/jobsManager"));
const LogsProcesses = lazy(() => import("./pages/jobsManager/processesLogs"));


function OnApi() {
  const { user } = useContext(Auth);

  return (
    <ConfigAntdProvider>
      <App className='OnApi'>
        <Routes>
          <Route path="/login" element={
            user !== null ? (
              <Navigate to="/" replace={true} />
            ) : (
              <Login />
            )
          } />

          <Route index={true} element={
            // entrada por defecto
            <Navigate to="/dashboard" />
          }
          />

          <Route path="/" element={
            user !== null ? (
              <Suspense fallback={<Loading />}>
                <LayoutApp />
              </Suspense>
            ) : (
              <Navigate to="/login" />
            )
          }>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/jobsManager" element={<JobsManager />} />

            <Route path="/jobsManager/processesLogs/:id" element={<LogsProcesses />} />

            <Route path="/admin" element={<Navigate to="/" />} />
            <Route path="/admin/users" element={<div> Admin User </div>} />
          </Route>
        </Routes >
      </App>
    </ConfigAntdProvider>
  );
}

export default OnApi;
