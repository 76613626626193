import React, { useState, useEffect, useContext } from 'react';
import { LockOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Alert, Form, Input } from 'antd';
import { http } from '../../http/axios';
import logo from '../../assets/logo.png';
import Auth from '../../context/auth';
import './Login.css';

function Login() {
  const [form] = Form.useForm();
  const { login } = useContext(Auth);
  const [loading, setLoading] = useState(false);
  const [failedLogin, setFailedLogin] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    http.post('/auth/login', values)
      .then(response => {
        setFailedLogin(false);
        if (response.message === 'Authorized') {
          login(response);
        }
      })
      .catch(error => {
        setFailedLogin(true);
        console.error('Error en la solicitud GET:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const update = () => {
    setFailedLogin(false);
  };

  return (
    <div className="Login">
      <div className='Form'>
        <div className='OnAPI'>
          <img src={logo} alt="OnAPI" />
          <h1>OnAPI 2.0</h1>
          <p>Control de Acceso</p>
        </div>
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '¡Por favor ingrese su nombre de usuario!' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Nombre de Usuario"
              onChange={update}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: '¡Por favor ingrese su contraseña!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Contraseña"
              type="password"
              onChange={update}
            />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => {
              if (failedLogin) {
                return <Alert message="Usuario o Contraseña incorrectos" type="error" showIcon />
              }
              return null;
            }}
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => {
              return (
                <Button type="primary" htmlType="submit" className="login-form-button"
                  icon={loading && <LoadingOutlined />}
                  disabled={
                    loading ||
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  Ingresar
                </Button>)
            }}
          </Form.Item>
        </Form>
      </div>

    </div >
  );
}

export default Login;
