import axios from 'axios';

// const urlServer = 'http://localhost:3000'; // Default
const urlServer = process.env.REACT_APP_API_URL; 

const headers = {
  'Content-Type': 'application/json',
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const currentPath = window.location.pathname;

    // console.log('error axios', error);

    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem('onfieldToken');
      sessionStorage.removeItem('onfieldLegacyToken');
      let errorMessage = error.response.data.message;
      if (errorMessage === 'Unauthorized' && currentPath !== '/login') {
        errorMessage = 'Tu sesión ha expirado. Ingresa nuevamente';
      }
      alert(errorMessage);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(async (config) => {
  const token = sessionStorage.getItem('OnApiToken');
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

const get = async (url, signal) => {
  const { data } = await axios.get(`${urlServer}${url}`, {
    headers,
    signal,
  });
  return data;
};

const post = async (url, body) => {
  const { data } = await axios.post(`${urlServer}${url}`, body, {
    headers,
  });
  return data;
};

const put = async (url, body) => {
  const { data } = await axios.put(`${urlServer}${url}`, body, {
    headers,
  });
  return data;
};

const _delete = async (url) => {
  const { data } = await axios.delete(`${urlServer}${url}`, {
    headers,
  });
  return data;
};

export const http = {
  get,
  post,
  put,
  delete: _delete,
};
